// browser, os 정보
export const client = () => {
  return {
    os: os(),
    browser: browser(),
  };
};

// os 정보
export const os = () => {
  const agent = window.navigator.userAgent;
  if (/(Mac)/gi.test(agent)) return "Mac";
  if (/(Windows)/gi.test(agent)) return "Windows";
  return "None";
};

// browser 정보
export const browser = () => {
  const agent = window.navigator.userAgent;
  if (/(Whale)/gi.test(agent)) return "Whale";
  if (/(Safari)/gi.test(agent)) return "Safari";
  if (/(Chrome)/gi.test(agent)) return "Chrome";
  return "None";
};

// 딜레이 함수
export const sleep = (duration: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

// window location hash uuid
export const getUUID = () => {
  const hash = window.location.hash.substring(1);
  const uuid = hash.split("/")[0].trim();
  return uuid;
};

export const comma = (num?: number | string | null) =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
