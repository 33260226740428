import seller from "./sellerAxios";
import {
  Broadcasts,
  LiveStatus,
  Pagination,
  Response,
  SignInBody,
  SignInPayload,
} from "./type";

export const signIn = (body: SignInBody) => {
  return seller.post<
    Response<{
      payload: SignInPayload;
    }>
  >("/auth/login", body);
};

export const signInUseUUID = (uuid: string) => {
  return seller.post<
    Response<{
      payload: SignInPayload;
    }>
  >("/auth/uuid", { uuid });
};

export const broadcasts = (
  params: Pagination & { searchWord?: string; liveStatus?: LiveStatus[] }
) => {
  let { page = 1, size = 100, searchWord, liveStatus } = params;

  if (searchWord) searchWord = encodeURIComponent(searchWord);

  return seller.get<Response<Broadcasts>>("broadcasts", {
    params: {
      page,
      size,
      liveStatus: liveStatus?.join(","),
      searchWord,
    },
  });
};
