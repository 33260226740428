import axios from "axios";
import { clearSession, getSession } from "../lib/session";

const instance = axios.create({
  baseURL: "https://seller-api.clickmate.co.kr",
});

instance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (err) {
    console.log(err);
    throw err;
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    console.log(err);
    throw err;
  }
);

export const setHeader = () => {
  const session = getSession();
  if (!session) {
    clearSession();
    return;
  }

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session.accessToken}`;
};

export default instance;
